@import './styles/abstracts/mixins';
@import './styles/abstracts/variables';

/* as base uses mixins it should be imported after mixins */
@import './styles/base/base';
@import './styles/base/utilities';
@import './styles/base/grid';

@import "primeng/resources/primeng.css";
@import 'src/styles/theme/theme.css';
@import '/node_modules/primeflex/primeflex.css';
@import "primeicons/primeicons.css";

@import 'ag-grid-community/styles/ag-grid.css';
@import "ag-grid-community/styles/ag-theme-alpine.css";

html,
body {
  height: 100vh;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.p-toast {
  width: auto;
  max-width: 40rem;
}

@mixin scrollbar(){
  &::-webkit-scrollbar {
      width: 14px;
      height: 18px;
  }
  &::-webkit-scrollbar-thumb {
      height: 6px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.15);
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
  }
  &::-webkit-scrollbar-corner {
      background-color: transparent;
  }
}

/* app login form component */
app-login-form {
  .p-inputtext {
    width: 100%;
    font-size: 1rem;
    font-size: 1.2rem;
  }

  p {
    margin: 0;
  }

  .p-button {
    width: 100%;
  }

  .p-password {
    width: 100%;
  }

  .p-divider-content {
    color: var(--text-color);
  }
}

/* app side nav component */
app-side-nav {
  .sidebar-icons button svg {
    font-size: 3rem;
  }
}

.p-tooltip {
  backdrop-filter: blur(5px);
  margin-left: 1rem;
  margin-top:1px;
  max-width: 15rem;
}

.p-tooltip .p-tooltip-text {
  background-color: rgba(255, 255, 255, 0.500);
  color: var(--text-color);
  font-size: 1.4rem;
  padding: 1rem 2rem;
  font-weight: 500;
}

/* app header component */
app-header {
  .xng-breadcrumb-link:hover {
    text-decoration: none;
    color: #0e4595;
  }

  .p-input-icon-left {
    width: 100%;
  }

  .p-inputtext {
    width: 100%;
  }

  .p-tabmenu .p-tabmenu-nav {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    gap: 2rem;
    border: none;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    padding: 0.5rem 0;
    border: none;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--text-color);
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    font-size: 2rem;
    line-height: 3rem;
  }

  .container__badge {
    span {
      background-color: #FF6961;
    }
    .pi {
      font-size: 2rem;
    }
  }
}

/* app action component */
app-actions {
  .tgl-btn {
    .p-button.p-button-icon-only {
      width: 4rem;
      border: none;
      box-shadow: 0px 4px 30px 1px rgba(133, 133, 133, 0.25);
      transition: all 0.3s ease;

      svg {
        font-size: 1.5rem;
      }
    }

    .p-button:focus {
      outline: none;
      box-shadow: none;
      box-shadow: 0px 4px 30px 1px rgba(133, 133, 133, 0.25);
    }
  }

  .action-bar-divider {
    height: 6rem;
  }

  .action-menu-btn {
    .p-button {
      background-color: #ffffff;
      color: var(--text-color);
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      border: none;
      font-size: 1.3rem;

      svg {
        font-size: 2rem;
      }
    }

    .p-button:hover {
      color: var(--primary-color);
    }
  }

  .sub-menu-btn {
    .p-button {
      background-color: #ffffff;
      border: 1px solid var(--text-color-secondary);
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      color: var(--text-color-secondary);
      gap: 1rem;
      width: 100%;

      svg {
        font-size: 1.5rem;
      }

      &:hover {
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
      }
    }

    .p-button:focus, &.button-selection .p-button {
      border: 1px solid var(--primary-color);
      color: var(--surface-a);
      background: linear-gradient(90deg, var(--primary-color) 0%, var(--highlight-text-color) 100%);
      box-shadow: none;
      outline: none;

      &:hover {
        color: var(--surface-a);
      }
    }
  }

  .add-btn {
    .p-button {
      background-color: #ffffff;
      padding: 0.5rem;
      border: none;
      outline: none;
      color: var(--text-color);

      .pi {
        font-size: 1.3rem;
      }
    }
  }
}

.app-action-overlay-panel {
  .p-overlaypanel-content {
    padding: 0;
    overflow-y: auto;
    max-height: 50vh;
    min-height: 15rem;
    @include scrollbar();
  }

  .checkbox-container {

    &.button-selection .checkbox-label {
      color: #134da2;
      font-weight: 600;
    }

    .checkbox-item {
      .p-checkbox.p-component {
        display: flex;
        align-items: center;

        .p-checkbox-box {
          border: solid 1px #c7c7c7;
          border-radius: 3px;
          width: 18px;
          height: 18px;

          &.p-highlight {
            border-color: #1F60C0;
          }
        }
      }
    }
  }
}

/* ag grid custom theme primary */
.custom-ag-theme-primary.ag-theme-alpine {
  // --ag-header-height: 2.5rem !important;
  --ag-row-height: 4rem !important;
  --ag-header-background-color: #f1f5f9;
  --ag-header-foreground-color: #000;
  --ag-header-font-weight: bold;
  --ag-font-size: 13px;

  --ag-border-color: #f0f5ff;
  --ag-secondary-border-color: #cbd5e1;
  --ag-input-border-color: #c7c7c7;

  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 80%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: #cbd5e1;

  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-height: 80%;
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-color: #cbd5e1;

  background: #FFF;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.10), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);

  .ag-header-cell {
    font-weight: bold;
  }

  /* .ag-cell {
    font-weight: bold;
  } */

  .ag-cell::after {
    content: '';
    position: absolute;
    top: 10%;
    bottom: 10%;
    right: 0;
    width: 1px;
    background-color: #cbd5e1;
  }

  .ag-row-even {
    background-color: #f1f5f9 !important;
  }

  .ag.-row-odd {
    background-color: #fff !important;
  }

  .ag-row:first-child {
    border-top: var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width);
  }

  .ag-header-row-column-filter {
    background-color: #fff;

    .ag-floating-filter {
      background-color: #fff;
    }
  }
}

/* ag grid custom theme basic */
.custom-ag-theme-basic.ag-theme-alpine {
  --ag-header-height: 6rem !important;
  --ag-row-height: 6rem !important;
  --ag-header-background-color: #ebedef;
  --ag-header-foreground-color: #000;
  --ag-header-font-weight: bold;
  --ag-font-size: 14px;

  --ag-border-color: #f0f5ff;
  --ag-borders: solid 1px;
  --ag-border-radius: 7px;

  --ag-secondary-border-color: #cbd5e1;
  --ag-input-border-color: #c7c7c7;

  // --ag-row-background: #f6f9fd;
  --ag-odd-row-background-color: #f6f9fd;

  --ag-header-column-separator-display: none;
  --ag-header-column-separator-height: 80%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: #cbd5e1;

  --ag-header-column-resize-handle-display: none;
  --ag-header-column-resize-handle-height: 80%;
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-color: #cbd5e1;

  background: #FEFFFE;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);

  .ag-header-cell {
    font-weight: bold;
  }

  .ag-header {
    border-bottom: 1px solid #CFCFCF;
    background: #ebedef;
  }

  .ag-cell {
    color: #696969;
    font-size: 14px;
    font-weight: 500;
  }
}

.app-aside {
  display: block;
  border-right: 1px solid $color-grey-dark-3;
  flex: 0 0 25%;

  .aside-content {
    height: 100%;
    overflow-y: scroll;
    @include scrollbar();
  }

  .aside {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__header-container {
      position: sticky;
      top: 0;
      background: #fff;
      padding: 1rem;
    }

    &__content-container {
      padding: 0 1rem;
    }
  }
}

.app-main {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.app-component {
  flex: 1 1 75%;
}

.p-checkbox .p-checkbox-box {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.disabled {
  pointer-events: none;
}

ag-grid-angular {
  scrollbar-width: .5em;
  ::-webkit-scrollbar {
      width: .5em;
      height: .5em;
      border-radius: 3px;
    }
  
    ::-webkit-scrollbar-corner {
      display: none;
    }
  
    ::-webkit-scrollbar-thumb {
          background: #d9d9d9;
            cursor: pointer;
            border-radius: 3px;
    }
  
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
        border-radius: 3px;
    }
}

.ag-body-horizontal-scroll,
 .ag-body-horizontal-scroll-viewport,
  .ag-body-horizontal-scroll-container {
  height: .5em !important;
  min-height: .5em !important;
  max-height: .5em !important;
}

.date-range-selector {
  .ranges-buttons {
    .p-component {
      font-size: small !important;
    }
  
    .button-selection .p-button {
      border: 1px solid var(--primary-color);
      color: var(--surface-a);
      background: linear-gradient(90deg, var(--primary-color) 0%, var(--highlight-text-color) 100%);
      box-shadow: none;
      outline: none;
    }
  }

    p-calendar {
      width: 100%;
  
      span input {
        text-align: center;
      }
    }
}

.p-dialog-mask {
  backdrop-filter: blur(10px);
}

.configurator-tabview {
  .p-tabview {
    height: 100%;
    border-radius: 0.8rem !important;
    overflow: hidden;
    box-shadow: 0px 4px 18px 2px #9090903b;
  }

  .p-tabview-nav {
    background-color: #EBEDEF;
    padding: 0.5rem 2rem 0 2rem;
    gap: 4rem;

    li {
      color: #ADAFBD;
      font-weight: 500;

      a {
        background: transparent !important;
      }
    }

    .p-highlight {
      color: var(--primary-color);

      .p-tabview-nav-link {
        border-width: 0 0 3px 0;
      }
    }
  }

  .p-tabview-panels {
    height: 90%;
    padding: 0 1.25rem;
  }

  .p-tabview-panel {
    height: 100%;
    padding: 2rem 1rem;
  }
}

.delete-confirm-dialog {
  width: 50vw;

  .cancel-btn {
    outline: var(--primary-color) solid 2px;
    background-color: transparent;
    color: var(--text-color);
    padding: 0.7rem 1.5rem;
    border: none;
    margin-right: 1rem;
    transition: none;
    box-shadow: none;

    span {
      font-weight: 500;
    }
  }

  .cancel-btn:hover {
    background-color: var(--primary-color);
    color: var(--surface-a);
  }

  .confirm-btn {
    outline: var(--text-color-red) solid 2px;
    background-color: transparent;
    color: var(--text-color);
    padding: 0.7rem 1.5rem;
    border: none;
    transition: none;
    box-shadow: none;

    span {
      font-weight: 500;
    }
  }

  .confirm-btn:hover {
    background-color: var(--text-color-red);
    color: var(--surface-a);
  }

  .p-dialog-title {
    font-size: 2rem;
  }

  .p-dialog-header-icons {
    scale: 1.5;
  }

  .p-confirm-dialog-message {
    font-size: 1.4rem;
  }
}

app-dropdown-cell-editor {
  .dropdown-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-flex;
      align-items: center;
    }
  }

  .custom-dropdown {
    width: 100%;
    height: 100%;
  }
}

.config-modules-home {
  &__header {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid var(--gray-300);    
  }

  &__header-left {
    display: flex;
    gap: 3rem;

    .p-inputtext {
      min-width: 35rem;
      border: 1px solid var(--gray-300);
    }
  }
 
  &__header-right {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  &__table-container {
    padding: 2rem 2rem 0;
    height: 100%;
  
      ag-grid-angular {
        height: calc(100% - 8rem);
      }
  
      .ag-header-cell-label {
        justify-content: center;
        margin-right: -1.5rem;
      }
  }
}

.custom-dialog-modal {
  .p-dialog-header {
    background-color: rgb(217 217 217);
    
    .p-dialog-title {
      color: #696969;
      font-size: 1.6rem;
      font-weight: 500;
    }

    .p-dialog-header-icons {
      scale: 1.5;
    }
  }
}

.p-placeholder {
  color: rgba(88, 88, 88, 0.47);  
}

.p-inputtext::placeholder {
  color: rgba(88, 88, 88, 0.47);  
}

.row-green-border {
  border-left: 4px solid green;
}

.group-action-buttons {
  .p-button {
    padding: 0.8rem 2rem;
  }

  .p-button-label {
    font-weight: 400;
    font-size: 1.5rem;
  }

  .p-button-icon-left {
    font-size: 2rem;
  }

  .save-btn, .edit-btn, .add-btn {
    .p-button {
      color: #fff;
    }
  }

  .delete-btn, .cancel-btn {
    .p-button {
      color: #f44336;
      border: solid 1px #f44336;
      background: transparent;
    }
  }
}

.action-icon-buttons {

  .p-button {
    background: transparent;
    border: none;

    svg {
      font-size: 2rem;
    }
  }

  .save-btn, .edit-btn, .restore-btn {
    .p-button {
      color: #1f60c0;
    }
  }

  .cancel-btn, .delete-btn {
    .p-button {
      color: #f00;
    }
  }
}

app-pre-check {
  .p-accordion-content {
    padding: 0;
  }

  .pi-check-circle {
    font-size: 2rem;

    &:hover {
      background: #e9eff8;
      padding: 0.5rem;
      border-radius: 50%;
    }
  }
}