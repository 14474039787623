.app-container {
  height: 100vh;
  display: grid;
  grid-template-rows: 9rem 6rem 1fr;
  grid-template-areas:
    'header'
    'actions'
    'main';

  .app-header {
    grid-area: header;
    border-bottom: 1px solid $color-grey-dark-3;
  }
  .app-actions {
    grid-area: actions;
    border-bottom: 1px solid $color-grey-dark-3;
  }
  .app-main {
    grid-area: main;
  }
}

// TODO: GLOBAL STYLE CHANGE FOR PRIMENG COMPONENTS
.p-component,
.p-dropdown-label {
  font-size: 1.4rem !important;
  font-weight: 500;
}

.p-calendar input {
  border: none;
  background: #edecfc;
}
